<template>
  <!-- 我的设计方案 -->
  <div class="my-design-scheme" v-show="visible">
    <div class="my-design-scheme-container">
      <div class="my-design-scheme-header">
        <div class="header-title">我的设计方案</div>
        <div class="header-button">
          <button @click="visible = false"><i class="iconfont icon-close"></i></button>
        </div>
      </div>
      <div class="my-design-scheme-body">
        <el-scrollbar class="body-left">
          <div class="sidenav">
            <!-- 柜类方案菜单 -->
            <div class="menu-title">柜类选配方案</div>
            <ul class="menu">
              <li class="menu-item" v-for="menuItem in schemeMenu.cabinetMenu" :key="menuItem.id">
                <div :class="['item-text', {'active': unfoldItemCode === menuItem.code}]" @click="unfoldMenu(menuItem.code)">
                  <strong>
                    {{ menuItem.name }}
                  </strong>
                  <el-icon v-if="unfoldItemCode === menuItem.code"><Plus/></el-icon>
                  <el-icon v-else><Minus/></el-icon>
                </div>
                <el-collapse-transition>
                  <ul class="submenu" v-show="unfoldItemCode === menuItem.code">
                    <li v-for="subMenuItem in menuItem.subMenu" :key="subMenuItem.id" :class="['submenu-item', {'active': activeItemCode === subMenuItem.code}]" @click="activeItemCode = subMenuItem.code; initPaging(); loadCabinetList();">
                      {{ subMenuItem.name }}
                    </li>
                  </ul>
                </el-collapse-transition>
              </li>
            </ul>
            <!-- 门板方案菜单 -->
            <div class="menu-title">门板选配方案</div>
            <ul class="menu">
              <li class="menu-item" v-for="menuItem in schemeMenu.plankMenu" :key="menuItem.id">
                <div :class="['item-text', {'active': unfoldItemCode === menuItem.code}]" @click="unfoldMenu(menuItem.code)">
                  <strong>
                    {{ menuItem.name }}
                  </strong>
                  <el-icon v-if="unfoldItemCode === menuItem.code"><Plus/></el-icon>
                  <el-icon v-else><Minus/></el-icon>
                </div>
                <el-collapse-transition>
                  <ul class="submenu" v-show="unfoldItemCode === menuItem.code">
                    <li v-for="subMenuItem in menuItem.subMenu" :key="subMenuItem.id" :class="['submenu-item', {'active': activeItemCode === subMenuItem.code}]" @click="activeItemCode = subMenuItem.code; initPaging(); loadPlankList();">
                      {{ subMenuItem.name }}
                    </li>
                  </ul>
                </el-collapse-transition>
              </li>
            </ul>
          </div>
        </el-scrollbar>
        <el-scrollbar class="body-right">
          <div class="scheme">
            <!-- 柜类方案列表 -->
            <template v-if="showType === 'cabinet'">
              <ul class="scheme-list" v-if="schemeList.cabinetList.length > 0" v-infinite-scroll="loadCabinetList" :infinite-scroll-disabled="pagingData.loading || pagingData.noMore">
                <li class="scheme-list-item" v-for="(item, index) in schemeList.cabinetList" :key="item.ID">
                  <swiper class="item-preview swiper-type" :modules="[Navigation, Pagination]" navigation :pagination="{ clickable: true }">
                    <swiper-slide>
                      <img :src="item.Image"/>
                    </swiper-slide>
                    <swiper-slide>
                      <img :src="item.SideViewImage"/>
                    </swiper-slide>
                    <swiper-slide>
                      <img :src="item.DoorImage"/>
                    </swiper-slide>
                  </swiper>
                  <div class="item-info">
                    <div class="info-row">
                      <span class="row-left primary-text overflow-display">
                        <router-link :to="'/designinfo/' + item.ID" target="_blank">{{ item.Room }}-{{ item.Name }}</router-link><br/>
                        {{ item.Price }}
                      </span>
                      <span class="row-right">
                        <el-button link title="查看" @click="goDesignInfo(item.ID)">
                          <el-icon size="16"><View/></el-icon>
                        </el-button>
                        <el-button link title="编辑" @click="goDesigner(item.SerialNo)">
                          <i class="iconfont icon-edit"></i>
                        </el-button>
                        <el-button link title="添加到购物车" @click="addToShoppingCart(item.ID)">
                          <i class="iconfont icon-shopping-cart"></i>
                        </el-button>
                        <el-popover trigger="click" popper-style="width:75px; min-width:10px; padding:5px; text-align:center; border-radius:8px;">
                          <template #reference>
                            <el-button link title="更多">
                              <i class="iconfont icon-more"></i>
                            </el-button>
                          </template>
                          <template #default>
                            <el-button link title="分享" @click="shareDesignScheme(item.SerialNo)">
                              <i class="iconfont icon-share"></i>&nbsp;分享
                            </el-button>
                            <br/>
                            <el-button link title="删除" @click="deleteDesignScheme(item.ID, index)">
                              <i class="iconfont icon-delete"></i>&nbsp;删除
                            </el-button>
                          </template>
                        </el-popover>
                      </span>
                    </div>
                    <div class="info-row">
                      <span class="row-left secondary-text overflow-display">{{ item.Width + item.SideLeftWidth + item.SideRightWidth }}*{{ item.Height }}*{{ item.Depth }} mm</span>
                      <span class="row-right secondary-text">{{ item.Time }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </template>
            <!-- 门板方案列表 -->
            <template v-else-if="showType === 'plank'">
              <ul class="scheme-list" v-if="schemeList.plankList.length > 0" v-infinite-scroll="loadPlankList" :infinite-scroll-disabled="pagingData.loading || pagingData.noMore">
                <li class="scheme-list-item" v-for="(item, index) in schemeList.plankList" :key="item.ID">
                  <div class="item-preview text-type">
                    <div class="preview-block" v-for="detail in item.List.slice(0, 3)" :key="detail">
                      <div><span class="overflow-display">{{ detail.Name }}</span><span class="overflow-display">价格 {{ detail.Price }}</span></div>
                      <div class="overflow-display">柜门*{{ detail.Doors.length }}、抽面*{{ detail.DrawerFronts.length }}、收口条*{{ detail.TrimStrips.length }}、见光板*{{ detail.VeneeredSides.length }}</div>
                    </div>
                    <div v-if="item.List.length > 3" class="preview-etc">……………………</div>
                  </div>
                  <div class="item-info">
                    <div class="info-row">
                      <span class="row-left primary-text overflow-display">
                        <router-link :to="'/designinfo/plank/cabinet-door/' + item.ID" target="_blank">{{ item.Name }}</router-link>
                      </span>
                      <span class="row-right">
                        <el-button link title="查看" @click="goDesignInfo(item.ID)">
                          <el-icon size="16"><View/></el-icon>
                        </el-button>
                        <el-button link title="编辑" @click="goDesigner(item.ID)">
                          <i class="iconfont icon-edit"></i>
                        </el-button>
                        <el-button link title="添加到购物车" @click="addToShoppingCart(item.ID)">
                          <i class="iconfont icon-shopping-cart"></i>
                        </el-button>
                        <el-popover trigger="click" popper-style="width:75px; min-width:10px; padding:5px; text-align:center; border-radius:8px;">
                          <template #reference>
                            <el-button link title="更多">
                              <i class="iconfont icon-more"></i>
                            </el-button>
                          </template>
                          <template #default>
                            <el-button link title="分享" @click="shareDesignScheme(item.ID)">
                              <i class="iconfont icon-share"></i>&nbsp;分享
                            </el-button>
                            <br/>
                            <el-button link title="删除" @click="deleteDesignScheme(item.ID, index)">
                              <i class="iconfont icon-delete"></i>&nbsp;删除
                            </el-button>
                          </template>
                        </el-popover>
                      </span>
                    </div>
                    <div class="info-row">
                      <span class="row-left primary-text overflow-display">{{ item.Price }}</span>
                      <span class="row-right secondary-text">{{ item.Time }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </template>
            <div class="no-scheme" v-if="pagingData.noMore && pagingData.total === 0">无选配方案</div>
            <div class="bottom-msg" v-loading="pagingData.loading">
              {{ pagingData.noMore && pagingData.total ? `共${pagingData.total}个方案` : '' }}
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { apiGetCabinetSchemeMenu, apiGetCabinetSchemeList, apiGetPlankSchemeList, apiDeleteCabinetScheme, apiDeletePlankScheme } from '@/api/designScheme';
import { apiAddGoodsToShoppingCart } from '@/api/shoppingCart';
import NumberUtil from '@/utils/number';

const visible = defineModel({ required: true });
const emit = defineEmits(['addGoods']);

const router = useRouter();

// 分页相关字段
const pagingData = reactive({
  pageNum: 0,
  pageSize: 12,
  total: 0,
  loading: false,
  noMore: false
});

// 方案菜单
const schemeMenu = reactive({
  cabinetMenu: [],
  plankMenu: []
});

// 方案列表
const schemeList = reactive({
  cabinetList: [],
  plankList: []
});

// 展开的一级菜单项
const unfoldItemCode = ref('');
// 激活的二级菜单项
const activeItemCode = ref('');

// 显示的方案类型
const showType = computed(() => {
  if (schemeMenu.cabinetMenu.flatMap(e => e.subMenu).map(e => e.code).includes(activeItemCode.value)) {
    return 'cabinet';
  } else if (schemeMenu.plankMenu.flatMap(e => e.subMenu).map(e => e.code).includes(activeItemCode.value)) {
    return 'plank';
  } else {
    return '';
  }
});

// 展开菜单
function unfoldMenu(code) {
  if (unfoldItemCode.value === code) {
    unfoldItemCode.value = '';
  } else {
    unfoldItemCode.value = code;
  }
}

// 加载方案菜单
async function loadMenu() {
  unfoldItemCode.value = '1-0';
  activeItemCode.value = '1-1';
  const res = await apiGetCabinetSchemeMenu();
  res.data.data.forEach((menuItem, index) => {
    schemeMenu.cabinetMenu.push({
      id: menuItem.ID,
      name: menuItem.Name,
      code: `${index + 1}-0`,
      subMenu: menuItem.ChildMenu.map((subMenuItem, i) => (
        { id: subMenuItem.ID, name: subMenuItem.Name, code: `${index + 1}-${i + 1}` }
      ))
    });
  });
  const cabinetMenuLength = schemeMenu.cabinetMenu.length;
  schemeMenu.plankMenu = [{
    id: 1,
    name: '柜门',
    code: `${cabinetMenuLength + 1}-0`,
    subMenu: [{ id: 1, name: '全部', code: `${cabinetMenuLength + 1}-1` }]
  }];
}

// 加载柜类方案列表
async function loadCabinetList(updateAfterDeletion = false) {
  pagingData.loading = true;
  let menuItemId = schemeMenu.cabinetMenu.find(e => e.code.split('-')[0] === activeItemCode.value.split('-')[0]).id;
  let subMenuItemId = schemeMenu.cabinetMenu.find(e => e.id === menuItemId).subMenu.find(e => e.code === activeItemCode.value).id;
  let param = {
    id: menuItemId,
    secMenu: subMenuItemId,
    page: updateAfterDeletion ? pagingData.pageNum : ++pagingData.pageNum,
    pageSize: pagingData.pageSize
  };
  const res = await apiGetCabinetSchemeList(param);
  res.data.data.forEach(e => e.Price = NumberUtil.toCNY(Number(e.Price)));
  if (updateAfterDeletion) {
    let curLastItem = schemeList.cabinetList[schemeList.cabinetList.length - 1];
    let newLastItem = res.data.data[res.data.data.length - 1];
    if (curLastItem?.ID !== newLastItem?.ID) {
      schemeList.cabinetList.push(newLastItem);
    }
  } else {
    schemeList.cabinetList = [ ...schemeList.cabinetList, ...res.data.data ];
  }
  pagingData.total = Number(res.data.count);
  if (schemeList.cabinetList.length >= pagingData.total) {
    pagingData.noMore = true;
  }
  pagingData.loading = false;
}

// 加载门板方案列表
async function loadPlankList(updateAfterDeletion = false) {
  pagingData.loading = true;
  let param = {
    page: updateAfterDeletion ? pagingData.pageNum : ++pagingData.pageNum,
    pageSize: pagingData.pageSize
  };
  const res = await apiGetPlankSchemeList(param);
  res.data.Data.forEach(e => {
    e.Price = NumberUtil.toCNY(Number(e.Price));
    e.List.forEach(item => {
      item.Price = NumberUtil.toCNY(Number(item.Price));
      item.TrimStrips = item.SideStrips.filter(stripSide => stripSide.Type === 'ss');
      item.VeneeredSides = item.SideStrips.filter(stripSide => stripSide.Type === 'vs');
      delete item.SideStrips;
    });
  });
  if (updateAfterDeletion) {
    let curLastItem = schemeList.plankList[schemeList.plankList.length - 1];
    let newLastItem = res.data.Data[res.data.Data.length - 1];
    if (curLastItem?.ID !== newLastItem?.ID) {
      schemeList.plankList.push(newLastItem);
    }
  } else {
    schemeList.plankList = [ ...schemeList.plankList, ...res.data.Data ];
  }
  pagingData.total = Number(res.data.count);
  if (schemeList.plankList.length >= pagingData.total) {
    pagingData.noMore = true;
  }
  pagingData.loading = false;
}

// 点击“查看”，跳转至选配详情
function goDesignInfo(id) {
  switch(showType.value) {
    case 'cabinet':
      window.open('/#/designinfo/' + id, '_blank');
      break;
    case 'plank':
      window.open('/#/designinfo/plank/cabinet-door/' + id, '_blank');
      break;
  }
}

// 点击“编辑”，跳转至选配器
function goDesigner(id) {
  switch(showType.value) {
    case 'cabinet':
      window.open('/#/go/' + id, '_blank');
      break;
    case 'plank':
      window.open('/#/designer/plank/cabinet-door/' + id, '_blank');
      break;
  }
}

// 点击“添加到购物车”，添加设计方案商品到购物车
function addToShoppingCart(id) {
  let type = '';
  switch(showType.value) {
    case 'cabinet':
      type = 'cabinet';
      break;
    case 'plank':
      type = 'cabcomp';
      break;
  }
  apiAddGoodsToShoppingCart({ goodsType: type, goodsId: id, qty: 1 }).then(res => {
    if (res.data.State === 'ok') {
      emit('addGoods');
      ElMessage.success('已添加至购物车！');
    } else {
      ElMessage.error(`添加至购物车失败！(${res.data.Msg})`);
    }
  });
}

// 点击“分享”，复制分享代码或链接，分享设计方案
function shareDesignScheme(id) {
  let copyText = '';
  let copyTextType = '';
  switch(showType.value) {
    case 'cabinet':
      copyText = id;
      copyTextType = '分享代码';
      break;
    case 'plank':
      copyText = `${location.origin}/#/designer/plank/cabinet-door/${id}`;
      copyTextType = '分享链接';
      break;
  }
  if (navigator.clipboard) {
    // 支持ClipboardAPI
    navigator.clipboard.writeText(copyText).then(() => {
      ElMessage.success(`${copyTextType}已复制到剪贴板！`);
    }).catch(() => {
      ElMessage.error(`${copyTextType}复制失败！`);
    });
  } else {
    // 不支持ClipboardAPI
    const input = document.createElement('input');
    input.value = copyText;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    ElMessage.success(`${copyTextType}已复制到剪贴板！`);
  }
}

// 点击“删除”，删除设计方案
function deleteDesignScheme(id, index) {
  ElMessageBox.confirm(
    '确定要删除此设计方案吗？',
    '提醒',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    if (showType.value === 'cabinet') {
      apiDeleteCabinetScheme({ id }).then(res => {
        if (res.data.State === 'ok') {
          schemeList.cabinetList.splice(index, 1);
          loadCabinetList(true);
          ElMessage.success('删除成功！');
        } else {
          ElMessage.error(`删除失败！(${res.data.Msg})`);
        }
      });
    } else if (showType.value === 'plank') {
      apiDeletePlankScheme({ ids: id }).then(res => {
        if (res.data.State === 'ok') {
          schemeList.plankList.splice(index, 1);
          loadPlankList(true);
          ElMessage.success('删除成功！');
        } else {
          ElMessage.error(`删除失败！(${res.data.Msg})`);
        }
      });
    }
  }).catch(() => {});
}

// 初始化分页
function initPaging() {
  pagingData.pageNum = 0;
  pagingData.pageSize = 12;
  pagingData.total = 0;
  pagingData.loading = false;
  pagingData.noMore = false;
  schemeList.cabinetList = [];
  schemeList.plankList = [];
}

// 监听“我的设计方案”弹窗的打开与关闭
watch(visible, async () => {
  if (visible.value) {
    // 打开“我的设计方案”弹窗时的操作
    document.body.style.setProperty('overflow', 'hidden');
    await loadMenu();
    await loadCabinetList();
  } else {
    // 关闭“我的设计方案”弹窗时的操作
    document.body.style.removeProperty("overflow");
    initPaging();
    schemeMenu.cabinetMenu = [];
    schemeMenu.plankMenu = [];
    unfoldItemCode.value = '';
    activeItemCode.value = '';
  }
}, { immediate: true });

onMounted(() => {});
</script>

<style lang="scss" scoped>
.my-design-scheme {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: rgb(0, 0, 0, 0.5);
  font-size: 16px;
  color: #333333;
  user-select: none;

  .my-design-scheme-container {
    width: 90%;
    min-width: 350px;
    max-width: 1440px;
    height: 70vh;
    margin: 10vh auto 0 auto;
    border-radius: 8px;
    background-color: white;

    .my-design-scheme-header {
      height: 20%;
      padding: 18px 20px;
      border-radius: 8px 8px 0 0;
      display: flex;
      background-image: url('@/assets/images/fav_bg.jpg');
      background-position: center;
      background-size: cover;
      color: white;

      .header-title {
        padding-left: 20px;
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 28px;
      }

      .header-button {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        button {
          width: 42px;
          height: 42px;
          padding: 0;
          border: 2px solid lightgrey;
          border-radius: 50%;
          color: lightgrey;
          background-color: rgba(0,0,0,0.5);
          cursor: pointer;
          i {
            font-size: 32px;
          }
        }
      }
    }

    .my-design-scheme-body {
      height: 80%;
      display: flex;

      .body-left {
        flex: 1;
        border-right: 1px solid lightgrey;

        .sidenav {
          padding: 20px 30px;

          .menu-title {
            margin: 10px 0;
            font-size: 18px;
            font-weight: bold;
          }

          ul.menu {
            margin-bottom: 20px;

            li.menu-item {

              .item-text {
                margin: 10px 0;
                padding: 15px;
                border: 1px solid #AAAAAA;
                border-radius: 8px;
                color: #AAAAAA;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .item-text:hover, .item-text.active {
                border-color: #333333;
                color: #333333;
              }

              ul.submenu {
                padding: 5px 15px 15px 15px;

                li.submenu-item {
                  padding: 10px 0;
                  border-bottom: 1px solid #F2F2F2;
                  color: #AAAAAA;
                  font-weight: bold;
                  cursor: pointer;
                }
                li.submenu-item:hover, li.submenu-item.active {
                  color: #333333;
                }
              }
            }
          }
        }
      }

      .body-right {
        flex: 4;

        :deep(.el-scrollbar__view:has(.scheme .no-scheme)) {
          height: 100%;
          .scheme{
            height: 100%;
          }
        }
        
        .scheme {
          padding: 20px 30px;

          .no-scheme {
            width: 100%;
            height: calc(100% - 50px);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .bottom-msg {
            width: 100%;
            height: 50px;
            font-size: 14px;
            line-height: 50px;
            text-align: center;
          }

          ul.scheme-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            li.scheme-list-item {
              width: 33.33%;
              padding: 10px;

              .item-preview {
                border: 1px solid #E6E6E6;
                border-radius: 8px;
              }

              .item-preview.swiper-type {
                --swiper-theme-color: grey;
                --swiper-navigation-size: 30px;
                width: 100%;
                height: 220px;
                background-color: #FAFAFA;
                text-align: center;
                img {
                    width: 100%;
                    height: 150px;
                    margin-top: 25px;
                    object-fit: contain;
                }
              }

              .item-preview.text-type {
                width: 100%;
                height: 220px;
                padding: 10px 30px;

                .preview-block {
                  padding: 5px 0;
                  div:first-child {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed #E6E6E6;
                    font-size: 12px;
                    line-height: 2em;
                    font-weight: bold;
                    span:first-child {
                      flex: 1.5;
                    }
                    span:last-child {
                      flex: 1;
                      text-align: right;
                    }
                  }
                  div:last-child {
                    font-size: 10px;
                    line-height: 2em;
                  }
                }
              }

              .item-info {
                font-size: 12px;
                line-height: 1.5em;

                .info-row {
                  margin: 3px 0;
                  display: flex;
                  align-items: center;

                  button {
                    margin: 0 2px 0 0;
                  }

                  .row-left {
                    flex: 1.5;
                    a:hover {
                      color: #666666;
                      text-decoration: underline;
                    }
                  }

                  .row-right {
                    flex: 1;
                    align-self: flex-start;
                    text-align: right;
                  }

                  .primary-text {
                    color: #333333;
                    font-weight: bold;
                  }

                  .secondary-text {
                    color: #999999;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.overflow-display {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 电脑
@media (min-width: 1200px) {
}

// 平板
@media (min-width: 768px) and (max-width: 1199px) {
  li.scheme-list-item {
    width: 50% !important;
  }
}

// 手机
@media (max-width: 767px) {
  li.scheme-list-item {
    width: 100% !important;
  }
}
</style>
