import { KeepAlive } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage/index.vue')
  },
  {
    path: '/about/:id',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/design',
    name: 'design',
    component: () => import('../views/DesignView.vue'),
    meta: {
      hideTopBar: true,
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/unitcabinet/:id/:room/:height/:width/:side/:roomstru',
    name: 'unitcabinet',
    component: () => import('../views/UnitCabinetView.vue'),
    meta: {
      hideTopBar: true,
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/designer/:id/:room/:height/:width/:side/:roomstru',
    name: 'designer',
    component: () => import('../views/DesignerView.vue'),
    meta: {
      hideTopBar: true,
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/material/:id',
    name: 'material',
    component: () => import('../views/MaterialView.vue')
  },
  {
    path: '/mydesign/:id',
    name: 'mydesign',
    component: () => import('../views/MyDesignView.vue')
  },
  {
    path: '/equipment/:id',
    name: 'equipment',
    component: () => import('../views/EquipmentView.vue')
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/newsinfo/:id/:newsid',
    name: 'newsinfo',
    component: () => import('../views/NewsInfoView.vue')
  },
  {
    path: '/contact/:id',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/goto',
    name: 'goto',
    component: () => import('../views/GotoView.vue'),
    meta: {
      hideTopBar: true,
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/go/:id',
    name: 'go',
    component: () => import('../views/GoView.vue'),
    meta: {
      hideTopBar: true,
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/shoppingcart',
    name: 'shoppingcart',
    // component: () => import('../views/ShoppingCartView.vue'),
    component: () => import('../views/ShoppingCart/index.vue'),
    meta: {
      // hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/order',
    name: 'order',
    // component: () => import('../views/OrderView.vue'),
    component: () => import('../views/Order/index.vue'),
    meta: {
      // hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/myorder',
    name: 'myorder',
    component: () => import('../views/MyOrderView.vue'),
    meta: {
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/designinfo/:id',
    name: 'designinfo',
    component: () => import('../views/DesignInfoView.vue'),
    meta: {
      hideBottomBar: true,
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  {
    path: '/designer/plank/cabinet-door/:id(\\d+)?',
    name: 'cabinetDoorDesigner',
    component: () => import('../views/CabinetDoorDesigner/index.vue'),
    meta: {
      hideBottomBar: true,
    }
  },
  {
    path: '/designinfo/plank/cabinet-door/:id(\\d+)',
    name: 'cabinetDoorDesignInfo',
    component: () => import('../views/CabinetDoorDesignInfo/index.vue'),
    meta: {
      hideBottomBar: true,
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
    meta: {
      // hideNav: true,
      // hideBottomNav: true
    }
  },
  //衣柜选择展示
  {
    path: '/wardrobe',
    name: 'wardrobe',
    component: () => import('../views/WardrobeView.vue'),
    meta: {
      // hideNav: true,
      // hideBottomNav: true
    }
  },

  //衣柜布局选配
  {
    path: '/wardrobeDesign',
    name: 'wardrobeDesign',
    component: () => import('../views/WardrobeDesignView.vue'),
    meta: {
      // hideNav: true,
      // hideBottomNav: true
      keepAlive: true
    }
  },
  //在线选配系统
  {
    path: '/onlineChoose',
    name: 'onlineChoose',
    component: () => import('../views/OnlineChoose.vue'),
  },
  //成品家居
  {
    path: '/furnishingView',
    name: 'furnishingView',
    component: () => import('../views/FurnishingView.vue')
  },
  //成品家居-沙发
  {
    path: '/furnishingSofaView',
    name: 'furnishingSofaView',
    component: () => import('../views/FurnishingSofaView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(window.location.hostname.indexOf('www.'))
  console.log(window.location.hostname.indexOf('localhost'))
  // 检查当前URL是否包含www
  if (window.location.hostname.indexOf('www.') !== 0 && window.location.hostname.indexOf('localhost') !== 0 && window.location.hostname.indexOf('192.') !== 0) {
    // 如果不包含www，重定向到带www的URL
    window.location.href = `http://www.${window.location.hostname}${window.location.pathname}${window.location.search}`
  } else {
    // 如果已经包含www，正常进行
    next()
  }
})

export default router
