<template>
  <div id="root">
    <TopBar v-if="!$route.meta.hideTopBar" />
    <div id="view-container">
      <router-view v-slot="{ Component }">
        <keep-alive >
          <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
        </keep-alive>
        <component v-if="!$route.meta.keepAlive" :is="Component" :key="$route.name" />
      </router-view>
    </div>
    <BottomBar v-if="!$route.meta.hideBottomBar" />
    <div class='login-wrap' v-if="loginWin">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>登 录</span>
            <el-button link @click="closeLoginWin">
              关闭
            </el-button>
          </div>
        </template>
        <el-form ref="ruleFormRef" :model="ruleForm" status-icon label-width="80px" class="demo-ruleForm">
          <el-form-item label="手机号码" prop="phoneNo">
            <el-input v-model.number="ruleForm.phoneNo" />
          </el-form-item>
          <el-row>
            <el-col :span="17">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="ruleForm.code" oninput="if(value.length>6)value=value.slice(0,6)" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-button @click="getVerifyCode" v-bind:disabled="!verifyCodeBtnState" style="float:right;width:95%;">{{
                verifyCodeBtnText }}</el-button>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="loginIn"> 登 录 </el-button>
            <el-button @click="closeLoginWin"> 取 消 </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import axios from 'axios'
import qs from 'qs'
import verify from '@/assets/js/verify'
import TopBar from '@/components/Bar/TopBar.vue';
import BottomBar from '@/components/Bar/BottomBar.vue';
// import ShoppingCartPart from './components/ShoppingCartPart'

export default ({
  components: {
    TopBar,
    BottomBar
    // ShoppingCartPart
  },
  setup() {
    const state = reactive({
      ruleForm: {
        phoneNo: '',
        code: ''
      },
      topNav: [],
      bottomNav: [],
      activeNav: [],
      loginWin: false,
      verifyCodeBtnText: '获取验证码',
      verifyCodeBtnState: true,
      showHandheldNav: false,
      showHandHeldSec: false
    })
    const getVerifyCode = () => {
      if (!verify.phoneNo(state.ruleForm.phoneNo)) {
        ElMessageBox.alert('请输入正确的手机号码', '提醒')
        return
      }

      state.verifyCodeBtnState = false
      verifyCodeBtn(59)
      const param = 'phoneNo=' + state.ruleForm.phoneNo

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/account/verifycode',
        data: param
      }).then(function (res) {
        const data = res.data
        if (data.State === 'error') {
          ElMessageBox.alert(res.Msg, '提醒')
        }
      })
    }
    const verifyCodeBtn = (ii) => {
      state.verifyCodeBtnText = ii
      setTimeout(() => {
        if (ii === 1) {
          state.verifyCodeBtnText = '获取验证码'
          state.verifyCodeBtnState = true
        } else {
          ii--
          verifyCodeBtn(ii)
        }
      }, 1000)
    }

    onMounted(() => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/menu/webmenu'
      }).then(function (res) {
        const data = res.data.data
        state.topNav = data.filter(item => item.Code !== 'contact')
        state.bottomNav = data.filter(item => item.Code !== 'design' && item.Code !== 'contact')
      })
    })
    const showNav = () => {
      state.showHandheldNav = !state.showHandheldNav
      state.showHandHeldSec = false
    }
    const showSecNav = (code) => {
      state.showHandHeldSec = !state.showHandHeldSec
      state.activeNav = state.topNav.filter(item => item.Code === code)[0]
    }
    const setLoginWinDisplay = (val) => {
      state.loginWin = val
    }
    const getLoginInfo = () => {
      return state.ruleForm
    }
    const clearLoginInfo = () => {
      state.ruleForm.phoneNo = ''
      state.ruleForm.code = ''
    }
    return {
      ...toRefs(state),
      showNav,
      showSecNav,
      getVerifyCode,
      verifyCodeBtn,
      setLoginWinDisplay,
      getLoginInfo,
      clearLoginInfo
    }
  },
  mounted() {

  },
  computed: {
    loginWin() {
      return this.$store.state.loginWin
    }
  },
  watch: {
    loginWin: {
      immediate: true,
      handler: function (val) {
        this.setLoginWinDisplay(val)
      }
    }
  },
  methods: {
    loginIn() {
      const that = this
      const param = that.getLoginInfo()
      if (!verify.phoneNo(param.phoneNo)) {
        ElMessageBox.alert('请输入正确的手机号码', '提醒')
        return
      }
      if (!param.code) {
        ElMessageBox.alert('请输入验证码', '提醒')
        return
      }
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'http://api.yumeigd.com/account',
        data: qs.stringify(param)
      }).then(function (res) {
        const data = res.data
        if (data.State === 'error') {
          ElMessageBox.alert(data.Msg, '提醒')
        } else {
          that.$store.commit('setToken', data.Token)
          that.$store.commit('setUserPowerDealerDesign', data.UserPower.DealerDesign)
          that.$store.commit('setNickName', data.NickName)
          that.$store.commit('setUserCard', data.UserCard)
          that.$store.commit('setLoginWin', false)
          that.$store.commit('setPhone', param.phoneNo)
          that.clearLoginInfo()
        }
      })
    },
    closeLoginWin() {
      this.$store.commit('setLoginWin', false)
    }
  }
})
</script>

<style lang="scss">
@import "./assets/styles/index.scss"; //引入方式
</style>

<style lang="scss" scoped>
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #view-container {
    flex: 1;
  }
}
</style>
